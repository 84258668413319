<template>
  <section id="explore_area" class="section_padding_top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-hotels"
              role="tabpanel"
              aria-labelledby="nav-hotels-tab"
            >
              <div class="row">
                <template v-for="hotel in hotelsSearch">
                  <div
                    v-if="!hotel.disabled"
                    :key="hotel.id"
                    class="col-lg-3 col-md-6 col-sm-6 col-12"
                  >
                    <div class="theme_common_box_two img_hover">
                      <div class="theme_two_box_img">
                        <router-link :to="`/hotel-details/${hotel.indentify}`">
                          <img
                            :src="hotel.photos[0]"
                            style="height: 200px; width: auto; min-width: 100%"
                            alt="img"
                          />
                        </router-link>
                        <p>
                          <i class="fas fa-map-marker-alt"></i>
                          {{ hotel.city }} {{ hotel.neighborhood }}
                        </p>
                      </div>

                      <div class="theme_two_box_content">
                        <h4>
                          <router-link
                            :to="`/hotel-details/${hotel.indentify}`"
                          >
                            {{ hotel.name }}</router-link
                          >
                        </h4>
                        <span class="medal" :class="hotel.plan">
                          <i class="fas fa-medal" style="font-size: 1.5rem"></i>
                        </span>
                        <p v-if="account">
                          <span class="review_rating">
                            <span
                              class="d-block"
                              style="margin-bottom: -0.5rem"
                            >
                              não sócios
                            </span>
                            <Strong>{{
                              hotel.price?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</Strong>
                            diária por pessoa
                          </span>
                        </p>
                        <h3 v-if="account" class="pt-0">
                          <span class="d-block"> Para sócios </span>
                          {{
                            hotel.member_price?.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                          <span> diária por pessoa </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
          style="margin: -4rem 0px 2rem 0px; text-align: center"
          v-if="!hotelsSearch?.length && route.query?.search"
        >
          Não encontramos comodidades em {{ route.query?.search }}.
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { Modal } from "bootstrap";
import { onMounted, ref, watch } from "vue";
import { hotelsStore } from "@/store/hotels.store";
import { storeToRefs } from "pinia";
import {
  fetchHotels,
  getHotelByFilters,
  formatText,
  getHotelsByCategories,
} from "@/root/firebase";
import { accountStore } from "@/store/account.store";
import { useRoute } from "vue-router";

const { hotelsSearch } = storeToRefs(hotelsStore());
const { account } = storeToRefs(accountStore());
const route = useRoute();

onMounted(() => {
  listHotels();
});

const listHotels = async () => {
  let { search, category } = route.query || {};

  if (category) {
    hotelsSearch.value = await getHotelsByCategories([category]);
    return;
  }

  if (!search) {
    const hotels = await fetchHotels();
    hotelsSearch.value = [];

    hotels.forEach((hotel) => {
      hotelsSearch.value.push(hotel.data());
    });

    return;
  }

  search = formatText(search);
  const hotels = await getHotelByFilters({ search });
  hotelsSearch.value = [];

  hotels.forEach((hotel) => {
    hotelsSearch.value.push(hotel);
  });
};

watch(
  () => route.query.search,
  () => {
    listHotels();
  }
);

watch(
  () => route.query.category,
  () => {
    listHotels();
  }
);

const modalRef = ref(null);
const dependent = ref(null);

const getDependent = (dep) => {
  dependent.value = dep;
};

const closed = () => {
  dependent.value = null;
  Modal.getInstance(modalRef.value)?.hide();
};
</script>
