<template>
  <section
    id="dashboard_main_arae"
    class="section_padding section_padding--mini"
  >
    <div class="container">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Clientes
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="titulos-tab"
            data-bs-toggle="tab"
            data-bs-target="#titulos"
            type="button"
            role="tab"
            aria-controls="titulos"
            aria-selected="false"
          >
            Titulos
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="convites-tab"
            data-bs-toggle="tab"
            data-bs-target="#convites"
            type="button"
            role="tab"
            aria-controls="convites"
            aria-selected="false"
          >
            Convites
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="comodidades-tab"
            data-bs-toggle="tab"
            data-bs-target="#comodidades"
            type="button"
            role="tab"
            aria-controls="comodidades"
            aria-selected="false"
          >
            Comodidades
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="hp-tab"
            data-bs-toggle="tab"
            data-bs-target="#hp"
            type="button"
            role="tab"
            aria-controls="hp"
            aria-selected="false"
          >
            Hoteis e Pousadas
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pc-tab"
            data-bs-toggle="tab"
            data-bs-target="#pc"
            type="button"
            role="tab"
            aria-controls="pc"
            aria-selected="false"
          >
            Parques e clubes
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="cupons-tab"
            data-bs-toggle="tab"
            data-bs-target="#cupons"
            type="button"
            role="tab"
            aria-controls="cupons"
            aria-selected="false"
          >
            Cupons
          </button>
        </li>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <form
            @submit.prevent="listProfiles(email)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-11">
              <input
                type="email"
                v-model="email"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="Buscar por e-mail"
              />
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Buscar</button>
            </div>
          </form>

          <div class="row">
            <template v-for="profile in profiles" :key="profile?.indentify">
              <div class="col-md-4 mb-3">
                <div class="card">
                  <div class="card-body position-relative">
                    <h5 class="card-title">{{ profile?.name }}</h5>
                    <p class="card-text">{{ profile.email }}</p>

                    <div
                      class="expand-contanct cursor-pointer link"
                      data-bs-toggle="modal"
                      data-bs-target="#modalContact"
                      @click="
                        (profileDetails = profile),
                          getReservations(profile, profile.reservations)
                      "
                    >
                      <i class="far fa-eye"></i>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Telefone: {{ profile?.phone }}
                    </li>
                    <li class="list-group-item" :class="profile?.plan">
                      Código: {{ profile.plan_indentify || "Free" }}
                    </li>
                    <li class="list-group-item">
                      Tipo para contato:
                      {{ profile?.contactPreference || "Todos" }}
                    </li>
                    <li class="list-group-item">
                      Titular:
                      {{
                        profile?.dep && profile?.plan_indentify
                          ? "Não"
                          : "Sim" || "Sem Título"
                      }}
                    </li>
                  </ul>
                  <div class="card-body">
                    <div class="d-block" v-if="!profile?.dep">
                      <input
                        v-model="profile.tt"
                        @keypress.enter="addTitle(profile.tt, profile)"
                        placeholder="Adicionar título"
                        class="px-2"
                      />
                      <div v-if="msgErrorTitle == profile.indentify">
                        <span class="text-danger">
                          Código do título não existe ou está em uso
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div
          class="tab-pane fade show"
          id="titulos"
          role="tabpanel"
          aria-labelledby="titulos-tab"
        >
          <form
            @submit.prevent="listTitles(title)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-9">
              <input
                type="text"
                v-model="title"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="Buscar por titulo"
              />
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Buscar</button>
            </div>
            <div class="col-auto">
              <select
                v-model="type"
                @change="listTitles(null, type)"
                class="form-select"
                aria-label="Planos"
              >
                <option value="ouro">Ouro</option>
                <option value="prata">Prata</option>
                <option value="bronze">Bronze</option>
                <option value="uso">Em Uso</option>
                <option value="sem_uso">Sem Uso</option>
              </select>
            </div>
            <div class="d-flex justify-content-end">
              <btn
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal8"
              >
                Adicionar
              </btn>
            </div>
          </form>

          <div class="row">
            <template v-for="title in titles" :key="title?.plan_indentify">
              <div class="col-md-4 mb-3">
                <div class="card">
                  <div class="form-check mx-2 my-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      @change="setUso(title)"
                      v-model="title.uso"
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Em uso
                    </label>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Código: {{ title.plan_indentify }}
                    </li>
                    <li class="list-group-item">Título: {{ title.plan }}</li>
                    <li class="list-group-item">
                      Desbloqueado
                      {{ title?.unlocked ? "Sim" : "Não" }}
                    </li>
                    <li class="list-group-item">
                      Válido: {{ title?.validy ? "Sim" : "Não" }}
                    </li>
                  </ul>
                  <div class="card-body" v-if="!title?.unlocked">
                    <button
                      class="btn btn-success btn-sm mt-2 float-end"
                      @click="unlocked(title, true)"
                    >
                      Desbloquear
                    </button>
                    <button
                      class="btn btn-danger btn-sm mt-2 float-end"
                      style="margin-right: 0.5rem; display: inline-block"
                      @click="_deleteTitle(title.plan_indentify)"
                    >
                      Deletar
                    </button>
                  </div>
                  <div class="card-body" v-else>
                    <button
                      class="btn btn-danger btn-sm mt-2 float-end"
                      @click="unlocked(title, false)"
                    >
                      Bloquear
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <span v-if="!titles.length" class="py-4">
              Não encontramos título
            </span>
          </div>
        </div>

        <div
          class="tab-pane fade show"
          id="convites"
          role="tabpanel"
          aria-labelledby="convites-tab"
        >
          <form
            @submit.prevent="listInvite(invite)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-11">
              <input
                type="text"
                v-model="invite"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="Buscar por titulo"
              />
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Buscar</button>
            </div>
            <div class="d-flex justify-content-end">
              <btn
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal9"
              >
                Adicionar
              </btn>
            </div>
          </form>

          <div class="row">
            <template v-for="invite in invites" :key="invite?.plan_indentify">
              <div class="col-md-4 mb-3" v-if="invite.validy">
                <div class="card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Código: {{ invite.plan_indentify }}
                    </li>
                    <li class="list-group-item">Plano: {{ invite.plan }}</li>
                    <li class="list-group-item">Título: Convidado</li>
                    <li class="list-group-item">
                      Valido: {{ invite?.validy ? "Sim" : "Não" }}
                    </li>
                  </ul>
                  <div class="col-md-12">
                    <button
                      class="btn btn-danger btn-sm my-2 float-end"
                      style="margin-right: 0.5rem; display: inline-block"
                      @click="_deleteInvite(invite.plan_indentify)"
                    >
                      Deletar
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <span v-if="!invites.length" class="py-4">
              Não encontramos cupom de convite
            </span>
          </div>
        </div>

        <div
          class="tab-pane fade show"
          id="comodidades"
          role="tabpanel"
          aria-labelledby="comodidades-tab"
        >
          <form
            @submit.prevent="listInvite(invite)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-10">
              <!-- <input type="text" v-model="invite" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Buscar por titulo"> -->
            </div>
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal2"
                @click="hotelEdit = null"
              >
                Adicionar
              </button>
            </div>
          </form>

          <div class="row">
            <template v-for="hotel in hotels" :key="hotel?.plan_indentify">
              <div
                v-if="
                  hotel?.category == 'Apartamento' || hotel?.category == 'Casa'
                "
                class="col-md-4 mb-3"
              >
                <div class="card">
                  <img :src="hotel?.photos[0]" class="card-img-top" />
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Categoria: {{ hotel.category }}
                    </li>
                    <li class="list-group-item">Nome: {{ hotel.name }}</li>
                    <li class="list-group-item">
                      ativo: {{ hotel?.disabled ? "Não" : "Sim" }}
                    </li>
                    <li class="list-group-item">Cidade: {{ hotel?.city }}</li>
                    <li class="list-group-item">
                      Bairro: {{ hotel?.neighborhood }}
                    </li>
                    <li class="list-group-item">
                      Localização: {{ hotel?.location }}
                    </li>
                    <li class="list-group-item">
                      N de pessoas: {{ hotel?.person }}
                    </li>

                    <li class="list-group-item">Plano: {{ hotel?.plan }}</li>
                    <li class="list-group-item" v-if="hotel.price">
                      Preço:
                      {{
                        hotel?.price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.member_price">
                      Preço para sócios:
                      {{
                        hotel?.member_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.high_season_price">
                      Preço alta temporada:
                      {{
                        hotel?.high_season_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-outline-danger btn-sm btn-outline mt-2"
                        @click="deleteHotel(hotel.indentify)"
                      >
                        Deletar
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-outline-primary btn-sm btn-outline mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modal2"
                        @click="editHotel(hotel)"
                      >
                        Editar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <span
              v-if="
                (!hotels.length && hotel?.category == 'Apartamento') ||
                (!hotels.length && hotel?.category == 'Casa')
              "
              class="py-4"
            >
              Não encontramos cupom de hoteis
            </span>
          </div>
        </div>

        <div
          class="tab-pane fade show"
          id="hp"
          role="tabpanel"
          aria-labelledby="hp-tab"
        >
          <form
            @submit.prevent="listInvite(invite)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-10">
              <!-- <input type="text" v-model="invite" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Buscar por titulo"> -->
            </div>
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal4"
                @click="hotelEdit = null"
              >
                Adicionar
              </button>
            </div>
          </form>

          <div class="row">
            <template v-for="hotel in hotels" :key="hotel?.plan_indentify">
              <div
                v-if="
                  hotel?.category == 'Hotel' || hotel?.category == 'Pousada'
                "
                class="col-md-4 mb-3"
              >
                <div class="card">
                  <img :src="hotel?.photos[0]" class="card-img-top" />
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Categoria: {{ hotel.category }}
                    </li>
                    <li class="list-group-item">
                      Link de Campanha: <br />
                      {{ hotel?.campLink }}
                    </li>
                    <li class="list-group-item">Nome: {{ hotel.name }}</li>
                    <li class="list-group-item">
                      ativo: {{ hotel?.disabled ? "Não" : "Sim" }}
                    </li>
                    <li class="list-group-item">Cidade: {{ hotel?.city }}</li>
                    <li class="list-group-item">
                      Bairro: {{ hotel?.neighborhood }}
                    </li>
                    <li class="list-group-item">
                      Localização: {{ hotel?.location }}
                    </li>
                    <li class="list-group-item">
                      N de pessoas: {{ hotel?.person }}
                    </li>

                    <li class="list-group-item">Plano: {{ hotel?.plan }}</li>
                    <li class="list-group-item" v-if="hotel.price">
                      Preço:
                      {{
                        hotel?.price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.member_price">
                      Preço para sócios:
                      {{
                        hotel?.member_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.high_season_price">
                      Preço alta temporada:
                      {{
                        hotel?.high_season_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-outline-danger btn-sm btn-outline mt-2"
                        @click="deleteHotel(hotel.indentify)"
                      >
                        Deletar
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-outline-primary btn-sm btn-outline mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modal4"
                        @click="editHp(hotel)"
                      >
                        Editar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <span
              v-if="
                (!hotels.length && hotel?.category == 'Hotel') ||
                (!hotels.length && hotel?.category == 'Pousada')
              "
              class="py-4"
            >
              Não encontramos cupom de hoteis
            </span>
          </div>
        </div>

        <div
          class="tab-pane fade show"
          id="pc"
          role="tabpanel"
          aria-labelledby="pc-tab"
        >
          <form
            @submit.prevent="listInvite(invite)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-10">
              <!-- <input type="text" v-model="invite" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Buscar por titulo"> -->
            </div>
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal5"
                @click="hotelEdit = null"
              >
                Adicionar
              </button>
            </div>
          </form>

          <div class="row">
            <template v-for="hotel in hotels" :key="hotel?.plan_indentify">
              <div
                v-if="hotel?.category == 'Parque' || hotel?.category == 'Clube'"
                class="col-md-4 mb-3"
              >
                <div class="card">
                  <img :src="hotel?.photos[0]" class="card-img-top" />
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Categoria: {{ hotel.category }}
                    </li>
                    <li class="list-group-item">
                      Link de Campanha: <br />
                      {{ hotel?.campLink }}
                    </li>
                    <li class="list-group-item">Nome: {{ hotel.name }}</li>
                    <li class="list-group-item">
                      ativo: {{ hotel?.disabled ? "Não" : "Sim" }}
                    </li>
                    <li class="list-group-item">Cidade: {{ hotel?.city }}</li>
                    <li class="list-group-item">
                      Bairro: {{ hotel?.neighborhood }}
                    </li>
                    <li class="list-group-item">
                      Localização: {{ hotel?.location }}
                    </li>
                    <li class="list-group-item">
                      N de pessoas: {{ hotel?.person }}
                    </li>

                    <li class="list-group-item">Plano: {{ hotel?.plan }}</li>
                    <li class="list-group-item" v-if="hotel.price">
                      Preço:
                      {{
                        hotel?.price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.member_price">
                      Preço para sócios:
                      {{
                        hotel?.member_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item" v-if="hotel.high_season_price">
                      Preço alta temporada:
                      {{
                        hotel?.high_season_price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-outline-danger btn-sm btn-outline mt-2"
                        @click="deleteHotel(hotel.indentify)"
                      >
                        Deletar
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-outline-primary btn-sm btn-outline mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modal5"
                        @click="editPc(hotel)"
                      >
                        Editar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <span
              v-if="
                (!hotels.length && hotel?.category == 'Hotel') ||
                (!hotels.length && hotel?.category == 'Pousada')
              "
              class="py-4"
            >
              Não encontramos cupom de hoteis
            </span>
          </div>
        </div>

        <!-- Cupons -->
        <div
          class="tab-pane fade show"
          id="cupons"
          role="tabpanel"
          aria-labelledby="cupons-tab"
        >
          <form
            @submit.prevent="listInvite(invite)"
            class="row justify-content-between items-center align-items-center g-3 mb-3"
          >
            <div class="col-10">
              <!-- <input type="text" v-model="invite" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Buscar por titulo"> -->
            </div>
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-sm btn-outline mt-2"
                data-bs-toggle="modal"
                data-bs-target="#modal3"
                @click="cupomEdit = null"
              >
                Adicionar
              </button>
            </div>
          </form>

          <div class="row">
            <template
              v-for="cupom in cupons.sort((a, b) => {
                return a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1;
              })"
              :key="cupom?.indentify"
            >
              <div class="col-md-4 mb-3">
                <div class="card">
                  <img
                    v-if="cupom?.photos?.length"
                    :src="cupom?.photos[0]"
                    class="card-img-top"
                  />
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Nome: {{ cupom.name }}</li>
                    <li class="list-group-item">
                      ativo: {{ cupom?.enabled ? "Sim" : "Não" }}
                    </li>
                    <li class="list-group-item">
                      Link da campanha: {{ cupom?.campBannerLink }}
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-outline-danger btn-sm btn-outline mt-2"
                        @click="deleteCupom(cupom.indentify)"
                      >
                        Deletar
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-outline-primary btn-sm btn-outline mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modal3"
                        @click="editCupom(cupom)"
                      >
                        Editar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <span v-if="!hotels.length" class="py-4">
              Não encontramos cupom de hoteis
            </span>
          </div>
        </div>

        <!-- Modal -->

        <div
          class="modal fade"
          ref="modalRefTitle"
          id="modal8"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Criar Título</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="container modal-body">
                        <div class="row justify-content-center">
                          <div class="col-lg-12">
                            <div class="vendor_form">
                              <div>
                                <form
                                  @submit.prevent="createTitle()"
                                  enctype="multipart/form-data"
                                >
                                  <div class="row g-2 mx-2">
                                    <div class="col-lg-12">
                                      <div
                                        class="form-group has-valindentifyation"
                                      >
                                        <label for="f-name">Cod.</label>
                                        <input
                                          autocomplete="off"
                                          v-model="formDataTitle.plan_indentify"
                                          type="text"
                                          class="form-control"
                                          placeholder="Cod."
                                        />
                                      </div>
                                    </div>

                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label for="f-plano">Plano</label>
                                        <select
                                          v-model="formDataTitle.plan"
                                          class="form-control form-select"
                                        >
                                          <option selected value="ouro">
                                            Ouro
                                          </option>
                                          <option value="prata">Prata</option>
                                          <option value="bronze">Bronze</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label for="f-plano"
                                          >Desbloqueado</label
                                        >
                                        <select
                                          v-model="formDataTitle.unlocked"
                                          class="form-control form-select"
                                        >
                                          <option selected :value="true">
                                            Sim
                                          </option>
                                          <option :value="false">Não</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label for="f-plano">Em uso</label>
                                        <select
                                          v-model="formDataTitle.uso"
                                          class="form-control form-select"
                                        >
                                          <option :value="true">Sim</option>
                                          <option selected :value="false">
                                            Não
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer mt-4">
                                    <button
                                      type="button"
                                      class="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      type="submit"
                                      class="btn btn-primary"
                                    >
                                      Adicionar
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalRefInvite"
          id="modal9"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Criar Convite
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="container modal-body">
                        <div class="row justify-content-center">
                          <div class="col-lg-12">
                            <div class="vendor_form">
                              <div>
                                <form
                                  @submit.prevent="createInvite()"
                                  enctype="multipart/form-data"
                                >
                                  <div class="row g-2 mx-2">
                                    <div class="col-lg-12">
                                      <div
                                        class="form-group has-valindentifyation"
                                      >
                                        <label for="f-name">Cod.</label>
                                        <input
                                          autocomplete="off"
                                          v-model="
                                            formDataInvite.plan_indentify
                                          "
                                          type="text"
                                          class="form-control"
                                          placeholder="Cod."
                                        />
                                      </div>
                                    </div>

                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label for="f-plano">Plano</label>
                                        <select
                                          v-model="formDataInvite.plan"
                                          class="form-control form-select"
                                        >
                                          <option selected value="ouro">
                                            Ouro
                                          </option>
                                          <option value="prata">Prata</option>
                                          <option value="bronze">Bronze</option>
                                        </select>
                                      </div>
                                    </div>
                                    <p>
                                      O convite criado tem duração de 3 meses e
                                      uso único.
                                    </p>
                                  </div>
                                  <div class="modal-footer mt-4">
                                    <button
                                      type="button"
                                      class="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      type="submit"
                                      class="btn btn-primary"
                                    >
                                      Adicionar
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalRef"
          id="modal2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Comodidade</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <AddHotel
                        @setHotel="setHotel($event)"
                        @closed="closed()"
                        :dependent="hotelEdit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalRefHp"
          id="modal4"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Hotéis e Pousadas
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <addParkTour
                        @setHotel="setHp($event)"
                        @closed="closed('hp')"
                        :dependent="hpEdit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalRefPc"
          id="modal5"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Parques e clubes
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <AddParkClub
                        @setHotel="setPc($event)"
                        @closed="closed('pc')"
                        :dependent="pcEdit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalRefCupom"
          id="modal3"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cupom</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <AddCupons
                        @setCupom="setCupom($event)"
                        @closed="closed('cupom')"
                        :dependent="cupomEdit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modalContact"
          id="modalContact"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cliente</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-6 my-1">
                      Nome:
                      <small class="text-body-secondary">
                        {{ profileDetails?.name }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      e-mail:
                      <small class="text-body-secondary">
                        {{ profileDetails?.email }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      CPF:
                      <small class="text-body-secondary">
                        {{ profileDetails?.cpf }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      RG:
                      <small class="text-body-secondary">
                        {{ profileDetails?.rg }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Parentesco:
                      <small class="text-body-secondary">
                        {{ profileDetails?.kinship }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Senha cadastral:
                      <small class="text-body-secondary">
                        {{ profileDetails?.password }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Telefone:
                      <small class="text-body-secondary">
                        {{ profileDetails?.phone }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Plano:
                      <small class="text-body-secondary">
                        {{ profileDetails?.plan }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Sexo:
                      <small class="text-body-secondary">
                        {{ profileDetails?.sex }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Data de Nascimento:
                      <small class="text-body-secondary">
                        {{ profileDetails?.date_birth }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Senha:
                      <small class="text-body-secondary">
                        {{ profileDetails?.password }}
                      </small>
                    </div>

                    <h3 class="d-block my-3">Endereço</h3>

                    <div class="col-md-6 my-1">
                      CEP:
                      <small class="text-body-secondary">
                        {{ profileDetails?.cep }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Cidade:
                      <small class="text-body-secondary">
                        {{ profileDetails?.cidade }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Bairro:
                      <small class="text-body-secondary">
                        {{ profileDetails?.bairro }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Endereço:
                      <small class="text-body-secondary">
                        {{ profileDetails?.logradouro }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      Numero:
                      <small class="text-body-secondary">
                        {{ profileDetails?.numero }}
                      </small>
                    </div>
                    <div class="col-md-6 my-1">
                      UF:
                      <small class="text-body-secondary">
                        {{ profileDetails?.uf }}
                      </small>
                    </div>

                    <h3 class="mt-3">Reservas</h3>

                    <div class="col-lg-12">
                      <div class="dashboard_common_table">
                        <div class="table-responsive-lg table_common_area">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th class="text-center">ID</th>
                                <th></th>
                                <th>Entrada</th>
                                <th>Saída</th>
                                <th>diárias | hóspedes</th>
                                <th>Status</th>
                                <th class="text-end">Pagamento &nbsp;&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-for="item in reservations"
                                :key="item?.indentify"
                              >
                                <tr v-if="item">
                                  <td class="text-center">
                                    <div>
                                      <img
                                        :src="item?.photos[0]"
                                        style="height: 50px"
                                        class="img-thumbnail"
                                        alt="..."
                                      />
                                    </div>
                                  </td>
                                  <td class="text-secondary fs-6">
                                    #{{ item?.indentify }}
                                    <span class="d-block"
                                      >Via Porto {{ item?.name }}</span
                                    >
                                  </td>
                                  <td class="text-secondary fs-6">
                                    <span class="text-body">
                                      {{
                                        moment(item?.entryDate).format(
                                          "DD/MM/yyyy dddd"
                                        )
                                      }}
                                    </span>
                                  </td>
                                  <td class="text-secondary fs-6">
                                    <span class="text-body">
                                      {{
                                        moment(item?.departureDate).format(
                                          "DD/MM/yyyy dddd"
                                        )
                                      }}
                                    </span>
                                  </td>
                                  <td class="text-secondary fs-6">
                                    <span class="text-body">
                                      {{ item?.dates?.length }} Diárias</span
                                    >
                                    para
                                    <span class="text-body">
                                      {{ item?.totalPerson }} Hóspedis</span
                                    >
                                    <br />
                                    <span
                                      v-for="(person, index) in item?.person"
                                      :key="person"
                                    >
                                      {{ person }}
                                      {{
                                        index == 0
                                          ? "Adultos"
                                          : index == 1
                                          ? "Crianças"
                                          : "Bb´s"
                                      }}
                                      &nbsp;
                                    </span>
                                  </td>
                                  <td
                                    class="fs-6 complete"
                                    :class="item.status"
                                  >
                                    <span>
                                      {{ item.status }}
                                    </span>
                                    <select
                                      v-model="item.status"
                                      @change="setStatus(item.status, item)"
                                      class="form-select"
                                      aria-label="Status"
                                    >
                                      <option value="Realizado">
                                        Realizado
                                      </option>
                                      <option value="Cancelado">
                                        Cancelado
                                      </option>
                                      <option value="Pendente">Pendente</option>
                                    </select>
                                  </td>
                                  <td class="fs-6 text-end">
                                    {{ item.formOfPayment }} -
                                    {{
                                      item.total?.toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    }}
                                    &nbsp;&nbsp;
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { reactive, ref } from "vue";
import { accountStore } from "@/store/account.store";
import { storeToRefs } from "pinia";
import {
  fetchProfiles,
  fetchTitles,
  deleteHotelIndentify,
  deleteCupomIndentify,
  fetchInvites,
  fetchHotels,
  fetchCupons,
  CREAT_INVITE_MANUAL,
  CREAT_TITLE_MANUAL,
  UPDATE_TITLE,
  GET_HOTEL,
  GET_TITLE,
  DB_UPDATE_PROFILE,
  deleteTitle,
  deleteInvite,
  UPDATE_HOTELS,
} from "@/root/firebase";
import { adminStore } from "@/store/admin.store";
import AddHotel from "@/components/profile/AddHotel.vue";
import AddCupons from "@/components/profile/AddCupons.vue";
import AddParkTour from "@/components/profile/addParkTour.vue";
import AddParkClub from "@/components/profile/addParkAndClub.vue";
import { Modal } from "bootstrap";
import moment from "moment";
import "moment/locale/pt-br";
import { notify } from "@kyvg/vue3-notification";
moment.locale("pt-br");

const { account } = storeToRefs(accountStore());
const { profiles } = storeToRefs(adminStore());
const email = ref("");
const title = ref("");
const titles = ref([]);
const invite = ref("");
const hotels = ref([]);
const cupons = ref([]);
const invites = ref([]);
const hotelEdit = ref(null);
const hpEdit = ref(null);
const pcEdit = ref(null);
const cupomEdit = ref(null);
const modalRef = ref(null);
const modalRefHp = ref(null);
const modalRefPc = ref(null);
const modalRefCupom = ref(null);
const modalRefTitle = ref(null);
const modalRefInvite = ref(null);
const type = ref("");
const msgErrorTitle = ref("");
const reservations = ref([]);
const _profile = ref("");
const profileDetails = ref(null);
const formDataTitle = reactive({
  plan: "ouro",
  plan_indentify: "",
  unlocked: false,
  uso: false,
  validy: true,
  new: true,
});
const formDataInvite = reactive({
  plan: "ouro",
  plan_indentify: "",
  unlocked: true,
  validy: true,
  new: true,
});

const createTitle = async () => {
  if (!formDataTitle.plan_indentify) {
    notify({
      title: "Erro",
      text: "Precisa ter um código",
      type: "error",
      duration: 5000,
    });
    return;
  }
  try {
    await CREAT_TITLE_MANUAL(formDataTitle.plan_indentify, formDataTitle);
    Modal.getInstance(modalRefTitle.value)?.hide();
    titles.value.push(formDataTitle);
  } catch (error) {
    notify({
      title: "Erro",
      text: "Erro ao criar titulo",
      type: "error",
      duration: 5000,
    });
  }
};

const createInvite = async () => {
  if (!formDataInvite.plan_indentify) {
    notify({
      title: "Erro",
      text: "Precisa ter um código",
      type: "error",
      duration: 5000,
    });
    return;
  }
  try {
    await CREAT_INVITE_MANUAL(formDataInvite.plan_indentify, formDataInvite);
    Modal.getInstance(modalRefInvite.value)?.hide();
    invites.value.push(formDataInvite);
  } catch (error) {
    notify({
      title: "Erro",
      text: "Erro ao criar convite",
      type: "error",
      duration: 5000,
    });
  }
};

const getReservations = (p, re) => {
  if (!re) return;

  _profile.value = p;

  reservations.value = [];
  reservations.value = re;
};

const setUso = (title) => {
  title.uso = true;
  UPDATE_TITLE(title.plan_indentify, title);
};

const closed = (ref) => {
  if (ref == "cupom") {
    cupomEdit.value = null;
    Modal.getInstance(modalRefCupom.value)?.hide();
    return;
  }
  if (ref == "hp") {
    hpEdit.value = null;
    Modal.getInstance(modalRefHp.value)?.hide();
    return;
  }
  if (ref == "pc") {
    pcEdit.value = null;
    Modal.getInstance(modalRefPc.value)?.hide();
    return;
  }
  if (ref == "title") {
    pcEdit.value = null;
    Modal.getInstance(modalRefTitle.value)?.hide();
    return;
  }
  if (ref == "invite") {
    pcEdit.value = null;
    Modal.getInstance(modalRefInvite.value)?.hide();
    return;
  }
  hotelEdit.value = null;
  Modal.getInstance(modalRef.value)?.hide();
};

const unlocked = (title, bol) => {
  title.unlocked = bol;
  UPDATE_TITLE(title.plan_indentify, title);
};

const addTitle = (title, profile) => {
  GET_TITLE(title).then((_title) => {
    const titleData = _title.data();
    if (titleData?.validy && !titleData?.unlocked) {
      profile.plan = titleData.plan;
      profile.plan_indentify = titleData?.plan_indentify;
      profile.plan_unlocked = titleData?.unlocked;
      profile.plan_valid = titleData?.validy;

      DB_UPDATE_PROFILE(profile.indentify, profile);
      msgErrorTitle.value = "";
      profile.tt = "";
    } else {
      msgErrorTitle.value = profile.indentify;
    }
  });
};

const setStatus = async (status, reserva) => {
  const { dates, hotel_indentify } = reserva || {};

  if (!dates || !hotel_indentify) return;

  const hotel = await GET_HOTEL(reserva.hotel_indentify);
  const blocked_dates = hotel.data().blocked_dates || [];
  blocked_dates.push(...dates);

  _profile.value.reservations = _profile.value.reservations.map((el) => {
    if (reserva.indentify == el.indentify) el.status = status;
    return el;
  });

  DB_UPDATE_PROFILE(_profile.value.indentify, _profile.value);
  if (status == "Realizado")
    UPDATE_HOTELS(reserva.hotel_indentify, { blocked_dates: blocked_dates });
};

const listProfiles = async (email) => {
  if (!account.value.admin) return;

  const profile = await fetchProfiles(email);

  profiles.value = [];

  profile.forEach((doc) => {
    profiles.value.push(doc.data());
  });
};

const listTitles = async (title, type) => {
  if (!account.value.admin) return;
  const _title = await fetchTitles(title, type);

  titles.value = [];

  _title.forEach((doc) => {
    titles.value.push(doc.data());
  });
};

const listInvite = async (title, type) => {
  if (!account.value.admin) return;

  const p = await fetchInvites(title, type);
  invites.value = [];

  p.forEach((doc) => {
    invites.value.push(doc.data());
  });
};

const _deleteTitle = async (id) => {
  const userConfirmed = window.confirm(
    "Tem certeza de que deseja deletar este título?"
  );

  if (!userConfirmed) {
    return;
  }

  try {
    await deleteTitle(id);
    titles.value = titles.value.filter((title) => title.plan_indentify !== id);
  } catch (err) {
    notify({
      title: "Erro ao deletar",
      text: err,
      type: "error",
      duration: 7000,
    });
  }
};

const _deleteInvite = async (id) => {
  const userConfirmed = window.confirm(
    "Tem certeza de que deseja deletar este convite?"
  );

  if (!userConfirmed) {
    return;
  }

  try {
    await deleteInvite(id);
    invites.value = invites.value.filter(
      (title) => title.plan_indentify !== id
    );
  } catch (err) {
    notify({
      title: "Erro ao deletar",
      text: err,
      type: "error",
      duration: 7000,
    });
  }
};

const listHotel = async () => {
  const p = await fetchHotels();
  hotels.value = [];
  p.forEach((doc) => {
    hotels.value.push(doc.data());
  });
};

const listCupons = async () => {
  const p = await fetchCupons();
  cupons.value = [];
  p.forEach((doc) => {
    cupons.value.push(doc.data());
  });
};

const setHotel = (hotel) => {
  if (!hotel) {
    hotelEdit.value = null;
    return;
  }

  if (hotelEdit.value) {
    hotels.value = hotels.value.map((hh) => {
      if (hh.indentify === hotel.indentify) hh = hotel;
      return hh;
    });
  } else hotels.value.push(hotel);
  hotelEdit.value = null;
};

const setHp = (hotel) => {
  if (!hotel) {
    hpEdit.value = null;
    return;
  }

  if (hpEdit.value) {
    hotels.value = hotels.value.map((hh) => {
      if (hh.indentify === hotel.indentify) hh = hotel;
      return hh;
    });
  } else hotels.value.push(hotel);
  hpEdit.value = null;
};

const setPc = (hotel) => {
  if (!hotel) {
    cpEdit.value = null;
    return;
  }

  if (cpEdit.value) {
    hotels.value = hotels.value.map((hh) => {
      if (hh.indentify === hotel.indentify) hh = hotel;
      return hh;
    });
  } else hotels.value.push(hotel);
  cpEdit.value = null;
};

const setCupom = (cupom) => {
  console.log("cupons", cupons.value);
  if (!cupom) {
    cupomEdit.value = null;
    return;
  }

  if (cupomEdit.value) {
    cupons.value = cupons.value.map((hh) => {
      if (hh.indentify === cupom.indentify) hh = cupom;
      return hh;
    });
  } else cupons.value.push(cupom);
  cupomEdit.value = null;
};

const editHotel = (hotel) => {
  hotelEdit.value = hotel;
};

const editHp = (hotel) => {
  hpEdit.value = hotel;
};

const editPc = (hotel) => {
  pcEdit.value = hotel;
};

const editCupom = (cupom) => {
  cupomEdit.value = cupom;
};

const deleteHotel = async (id) => {
  const userConfirmed = window.confirm(
    "Tem certeza de que deseja deletar este hotel?"
  );

  if (!userConfirmed) {
    return;
  }

  try {
    await deleteHotelIndentify(id);
    hotels.value = hotels.value.filter((hotel) => hotel.indentify !== id);
  } catch (err) {
    notify({
      title: "Erro ao deletar",
      text: err,
      type: "error",
      duration: 7000,
    });
  }
};

const deleteCupom = async (id) => {
  const userConfirmed = window.confirm(
    "Tem certeza de que deseja deletar este cupom?"
  );

  if (!userConfirmed) {
    return;
  }

  try {
    await deleteCupomIndentify(id);
    cupons.value = cupons.value.filter((cupom) => cupom.indentify !== id);
  } catch (err) {
    notify({
      title: "Erro ao deletar",
      text: err,
      type: "error",
      duration: 7000,
    });
  }
};

listProfiles();
listTitles();
listInvite();
listHotel();
listCupons();
// CREAT_INVITE();
</script>

<style scoped>
tr,
th {
  text-align: left;
}
td {
  font-size: 0.9rem !important;
  text-align: left;
}

.Pendente {
  color: #686868 !important;
}
.Realizado {
  color: #14a36c !important;
}
.Cancelado {
  color: #b91b1b !important;
}
.expand-contanct {
  cursor: pointer;
  z-index: 99;
  color: #333;
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  width: 2rem;
  height: 2rem;
}
</style>
