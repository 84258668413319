import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  setDoc,
  updateDoc,
  Timestamp,
  doc,
  deleteDoc,
  startAt,
  and,
  or,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { notify } from "@kyvg/vue3-notification";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAE5S680YasL31mQziktrIQPqawPiWOYOE",
  authDomain: "kaboo-643f5.firebaseapp.com",
  projectId: "kaboo-643f5",
  storageBucket: "kaboo-643f5.appspot.com",
  messagingSenderId: "119811071685",
  appId: "1:119811071685:web:83778348e8ca4db6c25aac",
  measurementId: "G-WR86HHMQ8F",
});

const db = getFirestore(firebaseApp);
const auth = getAuth();
const storage = getStorage(firebaseApp);

const USER = () =>
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      return user;
      // ...
    } else {
      return null;
    }
  });

const DB_GET_DOC = async (_collection, _doc) => {
  const docSnap = await doc(db, _collection, _doc);
  return docSnap;
};
const DB_SET_PROFILE = (docData) => setDoc(doc(db, "profiles"), docData);

const DB_GET_PROFILE = (uid) => getDoc(doc(db, "profiles", uid));

const DB_UPDATE_PROFILE = (uid, docData) => {
  const v = JSON.parse(JSON.stringify(docData));
  setDoc(doc(db, "profiles", uid), v);
};

const CREATE_USER = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    sendEmailVerification(auth.currentUser);
    return userCredential.user;
  } catch (err) {
    const errorMessage = err.message;
    throw new Error(errorMessage);
  }
};
const LOGIN = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (err) {
    const errorMessage = err.message;
    throw new Error(errorMessage);
  }
};
const SIGN_OUT = async () => {
  signOut(auth)
    .then(() => {
      localStorage.removeItem("token");
    })
    .catch((error) => {
      notify({
        title: "Erro ao sair",
        text: error,
        type: "error",
      });
    });
};

const SIGN_OUT_DEP = async (account, uid, data) => {
  try {
    await signOut(auth);
    await LOGIN(account.value.email, account.value.password);
    await DB_UPDATE_PROFILE(uid, data);
  } catch (error) {
    notify({
      title: "Erro ao sair",
      text: error,
      type: "error",
    });
  }
};

const getProfile = async (uid) => {
  const account = await DB_GET_PROFILE(uid);

  if (account.exists()) {
    return account.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    return null;
  }
};

const planIndentify = async (plan_indentify) => {
  let q = null;
  if (!plan_indentify) return false;

  q = query(
    collection(db, "profiles"),
    where("plan_indentify", "==", plan_indentify),
    limit(1)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

const fetchProfiles = async (email = null, plan_valid = null, page = 1) => {
  let start = (page - 1) * 25;
  let end = page * 25;

  let q = null;

  q = query(
    collection(db, "profiles"),
    orderBy("date_created"),
    startAt(start),
    limit(25)
  );

  if (email) {
    q = query(
      collection(db, "profiles"),
      where("email", "==", email),
      limit(1)
    );
  } else if (plan_valid) {
    q = query(
      collection(db, "profiles"),
      where("plan_valid", "==", plan_valid),
      orderBy("date_created"),
      startAt(start),
      limit(25)
    );
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

const fetchTitles = async (title = null, plan = null, page = 1) => {
  let start = (page - 1) * 25;
  let end = page * 25;

  let q = null;

  q = query(
    collection(db, "titles"),
    where("new", "==", true),
    orderBy("plan"),
    startAt(start),
    limit(50)
  );

  if (title) {
    q = query(
      collection(db, "titles"),
      where("plan_indentify", "==", title),
      where("new", "==", true),
      limit(1)
    );
  } else if (plan == "uso") {
    q = query(
      collection(db, "titles"),
      where("uso", "==", true),
      where("new", "==", true),
      orderBy("plan"),
      startAt(start),
      limit(50)
    );
  } else if (plan == "sem_uso") {
    q = query(
      collection(db, "titles"),
      where("uso", "==", false),
      where("new", "==", true),
      orderBy("plan"),
      startAt(start),
      limit(50)
    );
  } else if (plan) {
    q = query(
      collection(db, "titles"),
      where("plan", "==", plan),
      where("new", "==", true),
      orderBy("plan"),
      startAt(start),
      limit(50)
    );
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

const fetchInvites = async (title = null, plan = null, page = 1) => {
  let start = (page - 1) * 25;
  let end = page * 25;

  let q = null;

  q = query(
    collection(db, "invitations"),
    where("new", "==", true),
    orderBy("validy_date"),
    startAt(start),
    limit(50)
  );

  if (title) {
    q = query(
      collection(db, "invitations"),
      where("plan_indentify", "==", title),
      where("new", "==", true),
      limit(1)
    );
  } else if (plan) {
    q = query(
      collection(db, "invitations"),
      where("plan", "==", plan),
      where("new", "==", true),
      orderBy("validy_date"),
      startAt(start),
      limit(50)
    );
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

// ================ HOTEL ================

const fetchHotels = async (search = "", dates = null) => {
  if ((!search, !dates)) {
    const snapshot = await getDocs(
      collection(db, "hotels"),
      orderBy("name"),
      limit(2000)
    );
    return snapshot;
  }
  const q = query(
    collection(db, "hotels"),
    and(
      where("active", "==", true),
      where("active", "not-in", dates),
      or(
        where("city", "==", search.toLowerCase()),
        where("state", "==", search.toLowerCase()),
        where("neighborhood", "==", search.toLowerCase())
      )
    ),
    orderBy("name"),
    limit(2000)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot;

  // querySnapshot.forEach((doc) => {
  //   listDependents.value.push(doc.data());
  // });
};

const fetchCupons = async () => {
  const snapshot = await getDocs(
    collection(db, "cupom"),
    orderBy("name"),
    limit(100)
  );
  return snapshot;
};

const GET_HOTEL = async (uid) => {
  if (!uid) return;

  return getDoc(doc(db, "hotels", uid));
};

async function deleteHotelIndentify(docId) {
  if (!docId) return;
  try {
    await deleteDoc(doc(db, "hotels", docId));
  } catch (error) {
    throw new Error(error);
  }
}

async function deleteTitle(docId) {
  if (!docId) return;
  try {
    await deleteDoc(doc(db, "titles", docId));
  } catch (error) {
    throw new Error(error);
  }
}

async function deleteInvite(docId) {
  if (!docId) return;
  try {
    await deleteDoc(doc(db, "invitations", docId));
  } catch (error) {
    throw new Error(error);
  }
}

async function deleteCupomIndentify(docId) {
  if (!docId) return;
  try {
    await deleteDoc(doc(db, "cupom", docId));
  } catch (error) {
    throw new Error(error);
  }
}

async function getHotelsByCategories(categories) {
  try {
    const q = query(
      collection(db, "hotels"),
      where("category", "in", categories)
    );
    const querySnapshot = await getDocs(q);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return documents;
  } catch (error) {
    throw new Error(error);
  }
}

async function getCuppons() {
  try {
    const q = query(collection(db, "cupom"), where("enabled", "==", true));
    const querySnapshot = await getDocs(q);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return documents;
  } catch (error) {
    throw new Error(error);
  }
}

async function getHotelByFilters(filters) {
  try {
    let q = query(collection(db, "hotels"));

    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        q = query(q, where(key, "==", value));
      }
    }

    const querySnapshot = await getDocs(q);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return documents;
  } catch (error) {
    console.error("Erro ao obter documentos: ", error);
  }
}

function formatText(text) {
  // Remover acentos
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Converter para minúsculas
  text = text.toLowerCase();

  // Remover espaços, vírgulas, pontos e traços
  text = text.replace(/[\s,.-]/g, "");

  return text;
}

const GET_TITLE = (uid) => getDoc(doc(db, "titles", uid));
const UPDATE_TITLE = (uid, docData) => setDoc(doc(db, "titles", uid), docData);

const GET_INVITE = (uid) => getDoc(doc(db, "invitations", uid));
const UPDATE_INVITE = (uid, docData) =>
  setDoc(doc(db, "invitations", uid), docData);

const UPDATE_HOTELS = (uid, docData) =>
  updateDoc(doc(db, "hotels", uid), docData);

const DB_SET_HOTEL = (uid, docData) => setDoc(doc(db, "hotels", uid), docData);

const DB_SET_CUPOM = (uid, docData) => setDoc(doc(db, "cupom", uid), docData);

function generateRandomID() {
  // Função para gerar uma letra aleatória
  function randomLetter() {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Gera letras minúsculas de 'a' a 'z'
  }

  // Função para gerar um número aleatório com base na data e hora atual
  function randomNumber() {
    const date = new Date();
    const randomNumber =
      (date.getSeconds() * date.getMinutes() * date.getHours()) % 1000; // Gera um número entre 0 e 999
    return randomNumber.toString().padStart(3, "0"); // Garante que o número tenha três dígitos
  }

  let id = "";

  // Gera as quatro letras aleatórias
  for (let i = 0; i < 4; i++) {
    id += randomLetter();
  }

  // Gera os três números com base na data e hora atual
  id += randomNumber();

  return id;
}

const CREAT_TITLE = (plan = "ouro") => {
  for (let i = 0; i < 25; i++) {
    const randomID = generateRandomID();
    const docData = {
      plan,
      plan_indentify: randomID,
      unlocked: false,
      uso: false,
      validy: true,
    };

    setDoc(doc(db, "titles", randomID), docData);
  }
};

const CREAT_INVITE = (plan = "ouro") => {
  for (let i = 0; i < 25; i++) {
    const randomID = generateRandomID();
    const docData = {
      plan,
      plan_indentify: randomID,
      unlocked: true,
      validy: true,
      validy_date: moment().add(3, "months").format("yyyy-MM-DD"),
    };

    setDoc(doc(db, "invitations", randomID), docData);
  }
};

const CREAT_TITLE_MANUAL = async (ID, docData) => {
  await setDoc(doc(db, "titles", ID), JSON.parse(JSON.stringify(docData)));
};

const CREAT_INVITE_MANUAL = (ID, docData) => {
  const data = JSON.parse(JSON.stringify(docData));
  data["validy_date"] = moment().add(3, "months").format("yyyy-MM-DD");
  setDoc(doc(db, "invitations", ID), data);
};

export {
  db,
  Timestamp,
  auth,
  notify,
  getProfile,
  DB_GET_PROFILE,
  DB_GET_DOC,
  DB_SET_PROFILE,
  DB_UPDATE_PROFILE,
  CREATE_USER,
  LOGIN,
  SIGN_OUT,
  USER,
  SIGN_OUT_DEP,
  fetchHotels,
  GET_HOTEL,
  fetchProfiles,
  GET_TITLE,
  GET_INVITE,
  UPDATE_TITLE,
  UPDATE_INVITE,
  CREAT_INVITE,
  CREAT_TITLE,
  fetchTitles,
  fetchInvites,
  UPDATE_HOTELS,
  DB_SET_HOTEL,
  DB_SET_CUPOM,
  storage,
  storageRef,
  uploadBytes,
  getStorage,
  getDownloadURL,
  planIndentify,
  deleteHotelIndentify,
  deleteCupomIndentify,
  deleteTitle,
  deleteInvite,
  getHotelsByCategories,
  getHotelByFilters,
  formatText,
  getCuppons,
  fetchCupons,
  CREAT_TITLE_MANUAL,
  CREAT_INVITE_MANUAL,
};
